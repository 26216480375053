<template>
  <v-container fluid>
    <!-- <v-breadcrumbs :items="breadcrumbsItems" divider="."></v-breadcrumbs> -->
    <v-row>
      <!-- <v-col cols="12">
        <Calendar></Calendar>
      </v-col> -->

      <v-col cols="12" class="d-flex flex-column align-center">
        <div class="secondary--text mb-5">
          <v-icon color="secondary" small>mdi-tools</v-icon>
          app under construction
        </div>

        <div
          color="transparent"
          class="text-h1 d-flex align-center"
          style="font-weight: 900; color: #07617b"
        >
          <v-img max-width="80" alt="dealpartner" src="/images/Icon.png" />

          <div></div>
          dealpartner
        </div>

        dealpartner.lk

        <v-date-picker   no-title  class="mt-5" color="primary"></v-date-picker>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    // Calendar: () =>
    //   import(
    //     /* webpackChunkName: "component-calendar" */ "@/components/Calendar"
    //   ),
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    breadcrumbsItems: [
      {
        text: "Dashboard",
        disabled: true,
        href: "",
      },
    ],
  }),
  created() {
    // 
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>